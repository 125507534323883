import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import JumboPhoto from "../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="Practice Areas at Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>Practice Areas</h1>
				
				<ul>
					<li><Link to="/practice-areas/bankruptcy-law">Bankruptcy Law</Link></li>
					<li><Link to="/practice-areas/family-law">Family Law</Link></li>
					<li><Link to="/practice-areas/personal-injury-law">Personal Injury Law</Link></li>
				</ul>
			</Col>
		</Row>
	</Container>
</Layout>